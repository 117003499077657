import React, { useEffect, useState, useContext } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import ShopNavbar from "./StoreComponents/ShopNavbar";
import "react-phone-input-2/lib/plain.css";
import StoreFooter from "./StoreComponents/StoreFooter";
import { TiDelete } from "react-icons/ti";
import { cartProvider } from "../../contexts/CartContext";
import LoadingSpinner from "../../components/utils/LoadingSpinner";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import Div from "../../components/utils/Div";
import { Helmet } from "react-helmet-async";
import { BsFillTrashFill } from "react-icons/bs";
import { confirmAlert } from "react-confirm-alert";
import MapPicker from "../../components/utils/GoogleMapPicker";
import { domainProvider } from "../../contexts/DomainContext";
import { storeInformation } from "../../contexts/StoreInformationContext";
import axios from "../../components/utils/CustomerInterceptor";

const StoreCheckout = () => {
  const { customdomain, titleName } = useContext(domainProvider);
  let selectLanguage = i18next.language;
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const user = JSON.parse(localStorage.getItem("customer"));
  const { cartRefetch } = useContext(cartProvider);
  const { storeDetails } = useContext(storeInformation);
  const storeName = storeDetails?.data?.storeDetails?.store_name
    ? storeDetails?.data?.storeDetails?.store_name
    : window.location.href.split("/")[3];
  const { cartCount } = useContext(cartProvider);
  const {
    register: register3,
    handleSubmit: handleSubmit3,
    formState: { errors: error3 },
  } = useForm();
  const [methods, setMethods] = useState([]);
  const [pvAddresses, setPvAddress] = useState([]);
  const navigate = useNavigate();
  const [cart, setCart] = useState([]);
  const [cartWight, setCartWight] = useState([]);
  const [amount, setAmount] = useState(0);
  const [coupon, setCoupon] = useState(0);
  const customer = JSON.parse(localStorage.getItem("customer"));

  const [countrys, setCountrys] = useState([]);
  const [cuppon, setCuppon] = useState("");
  const [couponAmount, setCouponAmount] = useState(0);
  const userInfo = JSON.parse(localStorage.getItem("customer"));
  const [partnerData, setPartnerData] = useState([]);
  const [partner, setPartner] = useState();

  const [adBtnClick, setAdBtnClick] = useState(1);
  const [adType, setAdType] = useState(1);
  const [address, setAddress] = useState({});
  const [selectedPartner, setSelectedPartner] = useState();
  const [addressId, setAddressId] = useState(null);

  // get all previous addresses
  const getAllPvAddress = async () => {
    const res = await axios.get(
      `${process.env.REACT_APP_APIURL}/vendor/store/customer/getalladdress`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${customer ? customer.token : ""}`,
        },
      }
    );
    const response = await res.data;
    if (response.status_code === 200 && response.data) {
      setPvAddress(response.data);
    }
  };

  useEffect(() => {
    let cartitems = JSON.parse(localStorage.getItem("cart"));
    setAmount(cartitems?.order_total);
    setCart(cartitems?.itemDetails);
    setCartWight(cartitems?.total_weight);

    const getAllPaymentMethod = async () => {
      setLoading(true);
      const res = await axios.get(
        `${process.env.REACT_APP_APIURL}/vendor/store/customer/getallpaymentgateway`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${customer ? customer.token : ""}`,
          },
        }
      );
      const response = await res.data;
      if (response.status_code === 200 && response.data) {
        setMethods(response.data);
      }
      setLoading(false);
    };
    getAllPaymentMethod();

    getAllPvAddress();
  }, [cuppon]);

  const handleToCoupon = (e) => {
    e.preventDefault();
    const coupon = e.target.coupon.value;
    setCuppon(coupon);

    axios
      .get(
        `https://api.aitech.com.sa/vendor/store/customer/applycoupon?coupon_code=${coupon}&order_amount=${amount}`,
        {
          headers: {
            Authorization: `Bearer ${userInfo?.token}`,
          },
        }
      )
      .then((res) => {
        if (res.data.status_code === 200) {
          if (res.data.data.minimum_purchase_amount <= amount) {
            toast.success("Coupon Apply Successfully");
            if (res.data.data.coupon_type === "Percentage") {
              setCouponAmount((amount / 100) * res.data.data.maximum_discount);
            } else {
              setCouponAmount(res.data.data.maximum_discount);
            }
            setCoupon(true);
          } else {
            toast.error(
              `Minimum Amount ${res.data.data.minimum_purchase_amount}`
            );
          }
        } else {
          toast.error(res.data.message);
        }
      });
  };

  const handleToRemoveCoupon = () => {
    setCuppon(null);
    setCoupon(0);
    setCouponAmount(0);
  };

  useEffect(() => {
    const getAllCountry = async () => {
      const url = `${process.env.REACT_APP_APIURL}/vendor/torod/countries`;
      const res = await axios.get(url, {
        headers: {
          // 'Content-type': 'application/json',
          Authorization: `Bearer ${user?.token}`,
        },
      });
      const response = await res.data;
      setLoading(false);
      if (response.length > 0) {
        setCountrys(response);
      } else {
        return toast.info(response.message || response.err);
      }
    };
    getAllCountry();
  }, []);

  const handleAdBtnClick = async (value) => {
    setAdBtnClick(value);
    setAdType(value);
  };

  // marking new address pre selected
  useEffect(() => {
    const address = localStorage.getItem("address");

    if (address) {
      // pvAddresses.map
      if (pvAddresses.length > 0) {
        for (var i = 0; i < pvAddresses.length; i++) {
          if (pvAddresses[i].customer_landmark == address) {
            var addId = pvAddresses[i].id;
            var index = pvAddresses.length - 1;
            handlePartner(addId, index);
            return;
          } else {
          }
        }
      }
    }
  }, [pvAddresses]);

  // handle courier partners
  async function handlePartner(addId, index) {
    setAddressId(addId);
    const circles = document.getElementsByClassName("selectedAddress");

    for (var i = 0; i < circles.length; i++) {
      if (i == index) {
        circles[i].style.backgroundColor = "#236260";
      } else {
        circles[i].style.backgroundColor = "white";
      }
    }

    const partnerData = {
      total_weight: cartWight / 100,
      order_price: amount,
      no_of_items: Number(cartCount),
      customer_address_id: addId,
    };

    // setLoading(true)
    const url = `${process.env.REACT_APP_APIURL}/vendor/torod/courierpartnerbyitem`;
    const res = await axios.post(url, JSON.stringify(partnerData), {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user?.token}`,
      },
      // body: partnerData
    });
    const response = await res.data;

    if (response.length > 0) {
      setPartnerData(response);
    } else {
      return toast.error(
        response.message +
          "Courier partner not found, Please contact with admin, error message : "
      );
    }
  }

  // selected partner : delievery cost
  useEffect(() => {
    const partnerD =
      partnerData.length > 0 && selectedPartner
        ? partnerData.find((c) => c.id == selectedPartner.id)
        : null;

    const partnerL = localStorage.getItem("delieveryPartner");
    var deliveryData;
    if (partnerD && partnerD != null && partnerD != undefined) {
      deliveryData = {
        delivery_rate: partnerD?.rate,
        delivery_time: partnerD?.approximate_delivery_time,
        delivery_title: partnerD?.title,
        delivery_id: partnerD?.id,
      };
    } else {
      const partnerLocal = JSON.parse(partnerL);
      deliveryData = {
        delivery_rate: partnerLocal?.delivery_rate,
        delivery_time: partnerLocal?.delivery_time,
        delivery_title: partnerLocal?.delivery_title,
        delivery_id: partnerLocal?.delivery_id,
      };
    }
    setPartner(deliveryData);
  }, [selectedPartner, partnerData]);

  const onSubmit = async (data2) => {
    const data = new FormData();

    data["customer_contact"] = data2.store_phone;
    data["customer_address"] = data2.store_address;
    data["customer_alternate_contact"] = data2.store_alternate_contact;
    data["customer_email"] = data2.store_email;

    setLoading(true);

    const res = await axios.post(
      `${process.env.REACT_APP_APIURL}/vendor/store/customer/createaddress`,
      JSON.stringify(data),
      {
        headers: {
          "Content-type": "Application/json",
          Authorization: `Bearer ${customer ? customer.token : ""}`,
        },
      }
    );
    let response = await res.data;

    if (response && response.status_code == 200) {
      toast.success(response.message);
      getAllPvAddress();
      handleAdBtnClick(1);
      localStorage.setItem("address", address);
    } else {
      toast.error(response.message);
    }
    setLoading(false);
  };

  const onSubmit3 = async (data) => {
    // e.preventDefault();
    if (cart !== undefined) {
      if (!addressId) {
        return toast.info("Please select Delievery address");
      }
      if (!selectedPartner) {
        return toast.info("Please Select Courier Partner");
      }

      data["customer_address_id"] = addressId;
      data["order_price"] =
        amount +
        ((coupon && coupon?.delivery_rate) || 0) +
        ((selectedPartner && selectedPartner?.rate) || 0) +
        (amount / 100) * 10;
      data["order_sub_total_price"] = amount;
      data["shipping_price"] = selectedPartner.rate;
      data["tax_price"] = (amount / 100) * 10;
      data["coupon_code"] = cuppon;
      data["coupon_discount"] = couponAmount;
      data["product_arr"] = JSON.stringify(cart);
      data["no_of_items"] = cartCount;
      data["total_weight"] = cartWight;
      data["shipping_method"] = selectedPartner.title;
      data["torod_courier_partner_id"] = selectedPartner.id;
    }

    setLoading(true);
    const res = await axios.post(
      `${process.env.REACT_APP_APIURL}/vendor/store/customer/createorder`,
      JSON.stringify(data),
      {
        headers: {
          "Content-type": "Application/json",
          Authorization: `Bearer ${customer ? customer.token : ""}`,
        },
      }
    );
    let response = await res.data;
    if (response && response.data) {
      localStorage.setItem(
        "orderDetails",
        JSON.stringify({
          order_id: response.data.order_id,
          amount: response.data.order_price,
          payment_method: response.data.payment_method,
        })
      );
      localStorage.setItem("delieveryPartner", JSON.stringify(partner));
      // localStorage.removeItem("cart");
      cartRefetch();
      if (response?.data?.payment_method == 1) {
        toast.success("Your Order has been created successfully");
        localStorage.removeItem("orderDetails");
        if (customdomain === "aitech.com.sa") {
          navigate(storeName ? `/${storeName}` : `/`);
        } else {
          navigate(storeName ? `/` : `/`);
        }
      }
      if (response?.data?.payment_method == 2) {
        const getWay = methods.find((item) => item.payment_gateway_id === 2);
        if (customdomain === "aitech.com.sa") {
          navigate(
            storeName ? `/${storeName}/payment` : `${storeName}/checkout`,
            {
              state: {
                publishable_key: `${process.env.REACT_APP_MOYASAR_PUBLISHABLE_KEY}`,
                secret_key: `${process.env.REACT_APP_MOYASAR_SECRET_KEY}`,
              },
            }
          );
        } else {
          navigate(storeName ? `/payment` : `/checkout`, {
            state: {
              publishable_key: `${process.env.REACT_APP_MOYASAR_PUBLISHABLE_KEY}`,
              secret_key: `${process.env.REACT_APP_MOYASAR_SECRET_KEY}`,
            },
          });
        }
      }
    } else {
      toast.error(response.err);
    }
    setLoading(false);
  };

  // handle delete address
  function handleDltAddress(id) {
    confirmAlert({
      title: "Click yes to delete",
      message: "Are you sure to delete address",
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            setLoading(true);
            const url = `${process.env.REACT_APP_APIURL}/vendor/store/customer/deleteaddress/${id}`;
            const res = await axios.patch(url, {
              headers: {
                "Content-type": "Application/json",
                Authorization: `Bearer ${customer ? customer.token : ""}`,
              },
            });
            const response = await res.data;

            if (response) {
              setLoading(false);
              if (response.status_code == 200) {
                toast.success(response?.message);
                const address = pvAddresses.filter((add) => add.id != id);
                setPvAddress(address);
              } else {
                toast.error(response?.message);
              }
            }
          },
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  }

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <div className="">
        <ShopNavbar />
        <Helmet>
          <title>Order Checkout - {titleName}</title>
        </Helmet>
        <div className="bg-gray-100">
          <Div>
            <div className="px-5 mx-auto relative">
              {/* bredcumb */}
              <div className="py-4 container flex gap-3 items-center">
                <a
                  href={storeName ? `/${storeName}` : "/"}
                  className="text-primary text-base"
                >
                  <i className="fas fa-home"></i>
                </a>
                <span className="text-sm text-gray-400">
                  <i className="fas fa-chevron-right"></i>
                </span>
                <p className="text-gray-600 font-medium uppercase">
                  {t("checkout")}
                </p>
              </div>
              {/* checkout */}

              <div className="container lg:grid grid-cols-12 gap-6 items-start pb-16 pt-4">
                <div className="lg:col-span-8 border border-gray-200 px-4 py-4 rounded">
                  <div className="flex flex-col">
                    <h1 className="text-center text-2xl mt-5">
                      {selectLanguage === "ar"
                        ? "إضافة أو اختيار عنوان التسليم"
                        : "Add Or Select Delivery Address"}
                      :
                    </h1>

                    <div className="mt-3">
                      <div className="flex justify-center">
                        <div className="btn-group border-4 rounded-xl border-[#236260] ml-3">
                          <span
                            onClick={() => handleAdBtnClick(1)}
                            className={`btn btn-sm border-none ${
                              adBtnClick === 1
                                ? "text-white hover:text-primary bg-[#236260] hover:bg-[#236260] disabled"
                                : "bg-white text-[#236260] hover:bg-primary hover:text-white"
                            }`}
                          >
                            {t("Previous Address")}
                          </span>
                          <span
                            onClick={() => handleAdBtnClick(2)}
                            className={`btn btn-sm border-none ${
                              adBtnClick === 2
                                ? "text-white hover:text-primary bg-[#236260] hover:bg-[#236260] disabled"
                                : "bg-white text-[#236260] hover:bg-primary hover:text-white"
                            }`}
                          >
                            {t("Add_New")} {t("Address")}
                          </span>
                        </div>
                      </div>

                      <div className="my-5">
                        {adType === 1 && (
                          <>
                            {/* previous delievery card */}
                            <div className="flex flex-col py-4">
                              <h6 className="mb-2">
                                {t("Delievery")} {t("Address")}
                              </h6>

                              {pvAddresses.length > 0
                                ? pvAddresses.map((add, index) => {
                                    return (
                                      <div key={index} className="flex justify-between w-full bg-slate-200 rounded p-3 shadow mb-3 ">
                                        <div
                                          key={add?.id}
                                          className="flex flex-col w-11/12"
                                        >
                                          <div className="flex items-center gap-1 mb-1">
                                            <p className="font-semibold">
                                              {add?.customer_email}
                                            </p>{" "}
                                            <span className="rounded-full bg-slate-500 w-1 h-1" />
                                            <p className="text-sm ">
                                              {add?.customer_contact}
                                            </p>
                                          </div>
                                          <p className="capitalize">
                                            {add?.customer_landmark}
                                          </p>
                                        </div>
                                        <div className="flex my-auto gap-x-1">
                                          <div
                                            className="selectedAddress w-4 h-4 rounded-full border-2 border-slate-600 bg-white cursor-pointer"
                                            onClick={() => {
                                              handlePartner(add?.id, index);
                                            }}
                                          />
                                          <BsFillTrashFill
                                            className="cursor-pointer w-4 h-4"
                                            onClick={() => {
                                              handleDltAddress(add?.id);
                                            }}
                                          />
                                        </div>
                                      </div>
                                    );
                                  })
                                : null}
                            </div>
                            <form onSubmit={handleSubmit3(onSubmit3)}>
                              {/* Courier Partner */}
                              <div className="my-3">
                                <h1 className="text-xl mb-3">
                                  {selectLanguage === "ar"
                                    ? "اختر شريك الشحن الخاص بك"
                                    : "Select Courier Partner"}
                                </h1>
                                {/* partnerData */}
                                <select
                                  defaultValue={""}
                                  onChange={(e) =>
                                    setSelectedPartner(
                                      JSON.parse(e.target.value)
                                    )
                                  }
                                  className="input input-bordered focus:outline-none focus:border-gray-300 rounded-none w-full h-[40px]"
                                  name="Country"
                                  required
                                >
                                  <option readOnly>
                                    {selectLanguage === "ar"
                                      ? "اختر شريك الشحن الخاص بك"
                                      : "Select Your Courier Partner"}
                                  </option>
                                  {partnerData?.map((partner, idx) => (
                                    <option
                                      key={idx}
                                      value={JSON.stringify(partner)}
                                      className="flex justify-between"
                                    >
                                      {selectLanguage === "ar"
                                        ? partner.title_arabic
                                        : partner.title}
                                    </option>
                                  ))}
                                </select>
                              </div>

                              {/* <Map /> */}

                              {/* Select Payment Option */}
                              <div className="mt-3">
                                <h1 className="text-xl underline">
                                  {selectLanguage === "ar"
                                    ? "اختر خيار الدفع"
                                    : "Select Payment Option"}
                                </h1>
                                <div className="my-5">
                                  {
                                    <>
                                      {methods?.map((gateway) => (
                                        <div
                                          key={gateway.id}
                                          className="bg-white rounded p-4 flex justify-between mb-2 items-center"
                                          style={{ border: "1px inset" }}
                                        >
                                          <div className="gateway-info flex gap-2 items-center">
                                            <img
                                              src={gateway.gateway_image}
                                              className="w-20 h-16 object-contain"
                                              alt=""
                                            />
                                            <h3 className="text-lg font-medium">
                                              {gateway.gateway_name}
                                            </h3>
                                          </div>
                                          <input
                                            {...register3("payment_method")}
                                            type="radio"
                                            value={gateway.id}
                                          />
                                        </div>
                                      ))}
                                    </>
                                  }
                                </div>
                              </div>

                              <input
                                type="submit"
                                className="btn btn-primary w-full rounded text-white"
                                value={t("Proceed To Payment")}
                              />
                            </form>
                          </>
                        )}
                      </div>
                    </div>

                    {adType === 2 && (
                      <>
                        <MapPicker
                          onSubmit={(data) => {
                            onSubmit(data);
                          }}
                          email={user?.data.cust_email}
                          contact={user?.data.cust_phone}
                          alternate={true}
                          address=""
                          city=""
                          region=""
                          country=""
                          zip=""
                        />
                      </>
                    )}
                  </div>
                </div>

                <div className="lg:col-span-4 border border-gray-200 px-4 py-4 rounded mt-6 lg:mt-0">
                  <div className="p-4 bg-white border">
                    <div className="flex justify-between items-center">
                      <h1 className="text-lg md:text-2xl font-medium">
                        {selectLanguage === "ar"
                          ? "ملخص الطلب"
                          : "Order Summary"}
                      </h1>
                    </div>
                    <div className="overflow-x-auto my-2">
                      {cart
                        ? cart.map((item, index) => (
                            <div
                              key={index}
                              className="border p-2 flex justify-between items-end gap-2 "
                            >
                              <span className="">
                                {item.name}
                                <span className="text-sm text-gray-400 ml-2">
                                  x {item.quantity}
                                </span>
                              </span>
                              <span className="text-gray-400">
                                {item.price} x {item.quantity}
                              </span>
                            </div>
                          ))
                        : ""}
                      <div className="border p-2 flex justify-between items-end">
                        <span className="font-semibold">{t("Sub Total")} </span>
                        <span className="text-gray-800 font-bold">
                          {amount ? amount : 0}
                        </span>
                      </div>
                      <div className="border p-2 flex justify-between items-end gap-2 ">
                        <span className="">{t("Delivery Cost")} : </span>
                        <span className="text-gray-400">
                          {partner?.delivery_rate ? partner.delivery_rate : 0}
                        </span>
                      </div>
                      <div className="border p-2 flex justify-between items-end gap-2 ">
                        <span className="">
                          {t("Tax")} : <span>15%</span>
                        </span>
                        <span className="text-gray-400">
                          {(amount / 100) * 15}
                        </span>
                      </div>
                      {coupon > 0 && (
                        <div className="border p-2 flex justify-between items-end gap-2">
                          <span className="">
                            {t("Apply Coupon")}
                            <span className="text-sm text-gray-400 ml-2"></span>
                          </span>
                          <label
                            className=" flex items-center border border-info hover:bg-info rounded-full text-info hover:text-white cursor-pointer duration-300"
                            title="Remove Token"
                          >
                            <TiDelete
                              onClick={handleToRemoveCoupon}
                              className=" text-red-500 text-xl hover:text-white"
                            />
                          </label>
                          <span className="text-gray-400">
                            - {couponAmount}
                          </span>
                        </div>
                      )}

                      {!coupon && (
                        <form onSubmit={handleToCoupon} className="flex my-5">
                          <input
                            type="text"
                            name="coupon"
                            className="pl-4 w-full border border-r-0 border-primary py-2 px-3 rounded-l-md focus:ring-primary focus:border-primary text-sm"
                            placeholder="Coupon"
                          />
                          <button
                            type="submit"
                            className="bg-primary border border-primary text-white px-5 font-medium rounded-r-md hover:bg-transparent hover:text-primary transition text-sm font-roboto"
                          >
                            Apply
                          </button>
                        </form>
                      )}

                      <div className="border p-2 flex justify-between items-end">
                        <span className="font-semibold">Grand Total : </span>
                        <span className="text-gray-800 font-bold">
                          {amount -
                            ((coupon && couponAmount) || 0) +
                            ((partner && partner?.delivery_rate) || 0) +
                            (amount / 100) * 15}{" "}
                          ر.س
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Div>
        </div>
        <StoreFooter />
      </div>
      <div className={`${!loading2 && "hidden"}`}>
        <LoadingSpinner />
      </div>
    </>
  );
};

export default StoreCheckout;
